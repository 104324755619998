import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {FileSaverService} from "ngx-filesaver";
import {RequestDataService} from "../../service/request-data.service";
import {AppConfigService} from "../../service/app-config.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute} from "@angular/router";
import {ConfirmModalService} from "../../service/confirm-modal.service";
import {ClipboardService} from "ngx-clipboard";
import {UtilsService} from "../../service/utils.service";
import * as _ from "underscore";
import {forkJoin as observableForkJoin} from "rxjs";
import {TableModalComponent} from "../../basicComponent/table-modal/table-modal.component";
import * as _sh from 'lodash';
import {NzFormatEmitEvent} from "ng-zorro-antd";
import {HulkConfigServiceService} from "../../service/hulk-config-service.service";

@Component({
  selector: 'app-hulk-config-design-list',
  templateUrl: './hulk-config-design-list.component.html',
  styleUrls: ['./hulk-config-design-list.component.scss']
})
export class HulkConfigDesignListComponent implements OnInit {

  public searchId: String = null;
  public mainJson = {};
  public mainJsonText: any = '';

  tabIndex = 0;
  sqlFilePath: String = null;
  tableType = [
    {
      'code': 'normal',
      'name': '主表'
    },
    {
      'code': 'tablelist',
      'name': '明细多行表'
    }
  ];

  type = [
    {
      'code': 'integer',
      'name': 'integer'
    },
    {
      'code': 'string',
      'name': 'string'
    },
  ];


  condition_operator = [
    {
      'code': 'and',
      'name': 'and'
    },
    {
      'code': 'or',
      'name': 'or'
    },
  ];

  showColumnsOperation = [
    {
      'code': null,
      'name': '默认显示'
    },
    {
      'code': false,
      'name': '不显示'
    },
    {
      'code': true,
      'name': '显示'
    }
  ];


  direction = [
    {
      'code': 'input',
      'name': '传参'
    },
    {
      'code': 'sys',
      'name': '系统级'
    },
  ];

  operator = [
    {
      'code': '=',
      'name': '='
    },
    {
      'code': 'like',
      'name': 'like'
    },
    {
      'code': '!=',
      'name': '!='
    },
    {
      'code': '>',
      'name': '>'
    },
    {
      'code': '<',
      'name': '<'
    },
    {
      'code': '>=',
      'name': '>='
    },
    {
      'code': '<=',
      'name': '<='
    },
  ];


  constructor(
    private _httpClient: HttpClient,
    private modalService: NzModalService,
    private hulkconfigserviceservice: HulkConfigServiceService,
    private _FileSaverService: FileSaverService,
    private requestDataService: RequestDataService,
    public appConfigService: AppConfigService,
    private notification: NzNotificationService,
    private activatedRoute: ActivatedRoute,
    private confirmModal: ConfirmModalService,
    private clipboardService: ClipboardService,
    private utils: UtilsService
  ) {
  }

  selectMore(pListData, multiple?): number {
    //计数，有多少条记录被勾选了。
    let iCount = 0;
    _.each(pListData, oneData => {
      if (oneData['checked']) {
        iCount++;
      }
    });
    // tslint:disable-next-line:triple-equals
    if (multiple == true) {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请至少选择一条纪录！'
        });
        return iCount;
      }
    } else {
      if (iCount === 0) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '请选择一条纪录！'
        });
        return iCount;
      } else if (iCount > 1) {
        this.confirmModal.show('warning', {
          'title': '温馨提示',
          'content': '只能选择一条纪录！'
        });
        return iCount;
      }
    }
    return iCount;
  }

  checkAll(loopData, value: boolean, attributes?): void {
    loopData.forEach(data => {
      if (!data.disabled) {
        // 如果是有属性
        if (this.utils.Base_HasValue(attributes)) {
          if (data[attributes]) {
            data.checked = value;
          }
        } else {
          data.checked = value;
        }
      }
    });
    this.refreshStatus(loopData);
  }

  doRefresh() {
    this.confirmModal.show('confirm', {
      title: '是否需要刷新，所有的数据将不会保存。',
      suc: () => {
        this.ngOnInit();
      }
    });
  }

  refreshStatus(loopData): void {
    const validData = loopData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    loopData.allChecked = allChecked;
    loopData.indeterminate = (!allChecked) && (!allUnChecked);
  }


  deleteGroup(oneGroup) {
    this.confirmModal.show('confirm', {
      title: '是否将条件组，从列表之中删除',
      suc: () => {
        this.mainJson['conditionGroupList'] = _.without(this.mainJson['conditionGroupList'], oneGroup);
        delete this.mainJson['conditionGroup'][oneGroup];
        delete this.mainJson['condition'][oneGroup];
      }
    });
  }

  makeUp(temp) {

    function makeUpMain() {
      delete temp['conditionGroupList'];
    }


    function makeUpColumnPk(pColumn) {
      if (_sh.get(pColumn, ['pk_column'], false)) {
        pColumn['iscondition'] = true;
        pColumn['pk_column'] = true;
        pColumn['visible'] = false;
        pColumn['conditionColumn'] = pColumn['column'];
      }
    }

    function deleteChecked(pColumn) {
      delete pColumn['checked']
    }

    function deleteClass(pColumn) {
      delete pColumn['class']
    }

    function rename(pColumn) {
      if (pColumn['column'] != 'HR') {
        let temp01 = _sh.cloneDeep(pColumn);
        pColumn['name'] = temp01['column'] + '_NAME';
      }
    }

    function placeholder(pColumn) {
      if (_.contains(['select', 'poplist'], pColumn['component'])) {
        pColumn['placeholder'] = '请选择' + pColumn['lable'];
      } else if (_.contains(['input'], pColumn['component'])) {
        pColumn['placeholder'] = '请输入' + pColumn['lable'];
      }
      pColumn['comment'] = pColumn['lable'];
    }

    function makeUpColumns() {
      _sh.each(temp.showColumns.columns, oneColumn => {
        makeUpColumnPk(oneColumn); // 处理主键
        deleteChecked(oneColumn);
        deleteClass(oneColumn);
      })
      temp.conditionColumns[0].formname = _sh.cloneDeep(temp.conditionColumns[0].tablename);
      _sh.each(temp.conditionColumns[0].columns, oneColumn => {
        deleteChecked(oneColumn);
        rename(oneColumn);
        if (oneColumn['column'] == 'WF_STATE_01') {
        }
        placeholder(oneColumn);
      })

      _sh.each(temp.columns, oneColumn => {
        deleteChecked(oneColumn);
        delete oneColumn['visible'];
        delete oneColumn['attributes'];
      })

      _sh.each(temp.conditionGroupList, oneGroup => {
        _sh.each(temp.condition[oneGroup], oneColumn => {
          deleteChecked(oneColumn);
        })
      })

      _sh.each(temp.detailColumns, oneForm => {
        _sh.each(oneForm['columns'], oneColumn => {
          rename(oneColumn);
          _.each(oneColumn['columnGroup'], oneGroupColumn => {
            rename(oneGroupColumn);
          })
        })
      })
    }

    makeUpColumns();
    makeUpMain();
    return temp;
  }

  doShowJson() {
    let temp = _sh.cloneDeep(this.mainJson);
    temp = this.makeUp(temp);
    this.mainJsonText = this.utils.syntaxHighlight(temp);
    this.mainJsonText = _sh.trimStart(this.mainJsonText, '{');
    this.mainJsonText = _sh.trimEnd(this.mainJsonText, '}');
    this.mainJsonText = _sh.trim(this.mainJsonText);
  }

  createNotification(type: string, title: string, content: string): void {
    this.notification.create(
      type,
      title,
      content
    );
  }

  /**
   * 删除条件字段
   * @param oneGroup
   */
  deleteConditionGetColumns(oneGroup) {
    if (this.selectMore(this.mainJson['condition'][oneGroup], true) >= 1) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '确认要删除这些字段吗？',
        suc: () => {
          const selectList = _.filter(this.mainJson['condition'][oneGroup], (item) => {
            return item['checked'];
          });
          this.mainJson['condition'][oneGroup] = _.without(this.mainJson['condition'][oneGroup], selectList);
          _.each(selectList, oneColumn => {
            this.mainJson['condition'][oneGroup] = _.without(this.mainJson['condition'][oneGroup], oneColumn);
          })
        }
      });
    }
  }

  createGroup() {
    let count = this.mainJson['conditionGroupList'].length + 1;
    let groupName = "group_" + count;
    if (count < 10) {
      groupName = "group_0" + count;
    }
    this.mainJson['conditionGroup'][groupName] = "and";
    this.mainJson['condition'][groupName] = [];
    this.mainJson['conditionGroupList'].push(groupName);
  }


  doSave() {
    this.confirmModal.show('confirm', {
      title: '是否需要保存，如果原文件存在会覆盖源文件。',
      suc: () => {
        let temp = _sh.cloneDeep(this.mainJson);
        temp = this.makeUp(temp);
        this.saveFile(temp, this.searchId);
      }
    });
  }


  saveFile(jsonObject, filePath) {
    this.hulkconfigserviceservice.saveFile(jsonObject, filePath);
  }


  addNewConditionGetColumn(oneGroup) {
    let template = {
      "alias": "a",
      "column": "TEMP_NAME",
      "type": "string",
      "value": "TEMP_NAME",
      "direction": "input",
      "operator": "=",
      "condition_operator": "and"
    }
    this.mainJson['condition'][oneGroup].push(template);
  }

  /**
   * 选择一个数据
   * @param event
   * @param oneColumn
   * @param newName
   */
  addOneConditionColumn(event: NzFormatEmitEvent, oneColumn, newName) {
    oneColumn['column'] = newName;
    oneColumn['value'] = newName;
  }

  /**
   * 选择一个显示的字段。
   * @param event
   * @param oneColumn
   * @param newName
   */
  addShowColumn(event: NzFormatEmitEvent, oneColumn, newName) {
    oneColumn['column'] = newName;
  }


  addShowFVColumn(event: NzFormatEmitEvent, oneColumn, newName) {
    let link = _sh.reverse(this.utils.getLinks(_sh.get(event, ['node'])));
    oneColumn['fromValue'] = link;
  }

  deleteCondition(oneGroup, oneColumn: any) {
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '确认要删除该字段吗？',
      suc: () => {
        this.mainJson['condition'][oneGroup] = _sh.without(this.mainJson['condition'][oneGroup], oneColumn);
      }
    });
  }

  copyValue(oneColumn: any) {
    oneColumn['value'] = oneColumn['column'];
  }

  addTableList() {
    let template = {
      "alias": "x",
      "tablename": "x"
    }
    if (!_.has(this.mainJson, 'tableList')) {
      this.mainJson['tableList'] = [];
    }
    this.mainJson['tableList'].push(template);
  }

  deleteTableList(oneTable: any) {
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '确认要删除该表吗？',
      suc: () => {
        this.mainJson['tableList'] = _sh.without(this.mainJson['tableList'], oneTable);
      }
    });
  }

  addColumn() {
    let template = {
      "column": "ABC",
      "input": "ABC",
      "dictionary": null,
      "dictionaryPF": null,
      "visible": true
    };
    this.mainJson['columns'].push(template);
  }

  deleteColumns() {
    if (this.selectMore(this.mainJson['columns'], true) >= 1) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '确认要删除这些字段吗？',
        suc: () => {
          const selectList = _.filter(this.mainJson['columns'], (item) => {
            return item['checked'];
          });
          this.mainJson['columns'] = _.without(this.mainJson['columns'], selectList);
          _.each(selectList, oneColumn => {
            this.mainJson['columns'] = _.without(this.mainJson['columns'], oneColumn);
          })
        }
      });
    }
  }

  deleteOneColumn(oneColumn: any) {
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '确认要删除该字段吗？',
      suc: () => {
        this.mainJson['columns'] = _.without(this.mainJson['columns'], oneColumn);
      }
    });
  }

  selectColumns(isDict: Boolean = true) {
    _sh.each(this.mainJson['columns'], oneColumn => {
      if (isDict) {
        oneColumn['visible'] = !this.utils.Base_HasValue(_sh.get(oneColumn, ['role'], null))
      } else {
        oneColumn['visible'] = this.utils.Base_HasValue(_sh.get(oneColumn, ['role'], null))
      }
    })
  }

  deleteShowColumns() {
    if (this.selectMore(this.mainJson['showColumns']['columns'], true) >= 1) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '确认要删除这些字段吗？',
        suc: () => {
          const selectList = _.filter(this.mainJson['showColumns']['columns'], (item) => {
            return item['checked'];
          });
          this.mainJson['showColumns']['columns'] = _.without(this.mainJson['showColumns']['columns'], selectList);
          _.each(selectList, oneColumn => {
            this.mainJson['showColumns']['columns'] = _.without(this.mainJson['showColumns']['columns'], oneColumn);
          })
        }
      });
    }
  }


  autoAddNewShowColumn() {
    let that = this;
    let ajaxList = [];
    let columnsData = [];
    if (that.utils.Base_HasValue(that.mainJson['tableList'])) {
      _sh.each(that.mainJson['tableList'], oneTable => {
        ajaxList.push(this.requestDataService.getTableColumnsThisPf(oneTable['tablename']))
      })
      const searchTable = observableForkJoin(
        ajaxList
      );
      searchTable.subscribe(
        searchTableValues => {
          _sh.each(searchTableValues, oneTableValues => {
            columnsData = _sh.concat(columnsData, oneTableValues);
          });
          _sh.each(columnsData, oneColumn => {
            let template = {
              "column": oneColumn['COLUMN_NAME'],
              "title": oneColumn['COLUMN_COMMENT'],
              "visible": true,
              "export": true,
              "type": "head",
              "pk_column": oneColumn['COLUMN_NAME'] == "ID"
            };
            let foundData = this.utils.findData(this.mainJson['showColumns']['columns'], 'column', oneColumn['COLUMN_NAME']);
            if (!foundData) this.mainJson['showColumns']['columns'].push(template);
          })
        }
      );
    }
  }

  addNewShowColumn() {
    let template = {
      "column": "ABC",
      "title": "甲乙丙",
      "visible": true,
      "export": true,
      "type": "head",
    };
    this.mainJson['showColumns']['columns'].push(template);
  }

  deleteOneShowColumn(oneColumn: any) {
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '确认要删除该字段吗？',
      suc: () => {
        this.mainJson['showColumns']['columns'] = _sh.without(this.mainJson['showColumns']['columns'], oneColumn);
      }
    });
  }

  deleteOneConditionColumn(oneColumn: any) {
    this.confirmModal.show('confirm', {
      title: '温馨提示',
      content: '确认要删除该字段吗？',
      suc: () => {
        this.mainJson['conditionColumns'][0]['columns'] = _sh.without(this.mainJson['conditionColumns'][0]['columns'], oneColumn);
      }
    });
  }

  deleteConditionColumns() {
    if (this.selectMore(this.mainJson['conditionColumns'][0]['columns'], true) >= 1) {
      this.confirmModal.show('confirm', {
        title: '温馨提示',
        content: '确认要删除这些字段吗？',
        suc: () => {
          const selectList = _.filter(this.mainJson['conditionColumns'][0]['columns'], (item) => {
            return item['checked'];
          });
          _.each(selectList, oneColumn => {
            this.mainJson['conditionColumns'][0]['columns'] = _.without(this.mainJson['conditionColumns'][0]['columns'], oneColumn);
          })
        }
      });
    }
  }

  // 放到第一位
  pubItFirstGroup(oneColumn: any) {
    this.mainJson['showColumns']['columns'] = _.without(this.mainJson['showColumns']['columns'], oneColumn);
    this.mainJson['showColumns']['columns'].unshift(oneColumn);
  }

  // 放置到最后一位
  pubItLastComponent(oneColumn) {
    this.mainJson['showColumns']['columns'] = _.without(this.mainJson['showColumns']['columns'], oneColumn);
    this.mainJson['showColumns']['columns'].push(oneColumn);
  }

  // 往前放
  putForward(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num - 1];
      pArray[num - 1] = pData;
      pArray[num] = a;
    }
  }

  // 往后放
  putItBack(pArray, pData) {
    if (pArray instanceof Array) {
      let num = pArray.indexOf(pData);
      let a = pArray[num + 1];
      pArray[num + 1] = pData;
      pArray[num] = a;
    }
  }


  addNewConditionColumn() {
    let template = {
      "column": "ABC",
      "name": "ABC_NAME",
      "component": "input",
      "lable": "甲乙丙",
      "comment": "甲乙丙",
      "placeholder": "请输入甲乙丙",
      "model": null,
      "visible": true
    };
    this.mainJson['conditionColumns'][0]['columns'].push(template);
  }

  rebuildMainJson() {
    if (!this.utils.Base_HasValue(_sh.get(this.mainJson, ['showColumns'], {}))) {
      this.mainJson['showColumns'] = {
        "tablename": "temp_tablename",
        "columns": []
      }
    }
    if (!this.utils.Base_HasValue(_sh.get(this.mainJson, ['conditionColumns'], []))) {
      this.mainJson['conditionColumns'] = [
        {
          "tablename": "system_user",
          "formname": "system_user",
          "title": "查询",
          "type": "normal",
          "columns": []
        }
      ]
    }

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res) => {
      this.searchId = _sh.get(res, ['searchId']);
      this.requestDataService.getDataByJson(this.searchId).subscribe(jsons => {
        let status = _sh.get(jsons, ['status'], 200);
        if (status == 200) {
          this.mainJson = jsons;
        } else if (status == 500) {
          this.mainJson = {
            "_type": "normal",
            "selectText": "SELECT a.*",
            "formText": "from system_user a",
            "whereText": "where a.U_DELETE = 1",
            "groupbyText": "",
            "orderbyText": "",
            "countText": "",
            "conditionGroup": {
              "group_01": "and"
            },
            "condition": {
              "group_01": []
            },
            "columns": [],
            "role_group": [],
            "showColumns": {
              "tablename": "system_user",
              "columns": []
            },
            "detailColumns": [],
            "conditionColumns": [],
            "tableList": [
              {
                "alias": "a",
                "tablename": "system_user"
              }
            ]
          }
        }
        this.rebuildMainJson();
        this.mainJson['conditionGroupList'] = _sh.keys(this.mainJson['conditionGroup']);
        this.sqlFilePath = _sh.get(jsons, 'sqlFilePath', null);
        this.selectColumns(true); // 刷新字典
        this.selectColumns(false); // 刷新权限
      });
    });
  }


  autoAddNewConditionColumn() {
    let that = this;
    let ajaxList = [];
    let columnsData = [];
    if (that.utils.Base_HasValue(that.mainJson['tableList'])) {
      _sh.each(that.mainJson['tableList'], oneTable => {
        ajaxList.push(this.requestDataService.getTableColumnsThisPf(oneTable['tablename']))
      })
      const searchTable = observableForkJoin(
        ajaxList
      );
      searchTable.subscribe(
        searchTableValues => {
          _sh.each(searchTableValues, oneTableValues => {
            columnsData = _sh.concat(columnsData, oneTableValues);
          });
          _sh.each(columnsData, oneColumn => {
            let template = {
              "column": oneColumn['COLUMN_NAME'],
              "name": `${oneColumn['COLUMN_NAME']}_NAME`,
              "component": "input",
              "lable": `${oneColumn['COLUMN_COMMENT']}`,
              "comment": `${oneColumn['COLUMN_COMMENT']}`,
              "placeholder":  `请输入${oneColumn['COLUMN_COMMENT']}`,
              "model": null,
              "require": false,
              "visible": true
            };
            let foundData = this.utils.findData(this.mainJson['conditionColumns'][0]['columns'], 'column', oneColumn['COLUMN_NAME']);
            if (!foundData) this.mainJson['conditionColumns'][0]['columns'].push(template);
          })
        }
      );
    }
  }

  autoAddNewConditionGetColumn(oneGroup) {
    let that = this;
    let ajaxList = [];
    let columnsData = [];
    if (that.utils.Base_HasValue(that.mainJson['tableList'])) {
      _sh.each(that.mainJson['tableList'], oneTable => {
        ajaxList.push(this.requestDataService.getTableColumnsThisPf(oneTable['tablename']))
      })
      const searchTable = observableForkJoin(
        ajaxList
      );
      searchTable.subscribe(
        searchTableValues => {
          _sh.each(searchTableValues, oneTableValues => {
            columnsData = _sh.concat(columnsData, oneTableValues);
          });
          _sh.each(columnsData, oneColumn => {
            let template = {
              "alias": "a",
              "column": oneColumn['COLUMN_NAME'],
              "type": "string",
              "value": oneColumn['COLUMN_NAME'],
              "direction": "input",
              "operator": "=",
              "condition_operator": "and"
            };
            let foundData = this.utils.findData(this.mainJson['condition'][oneGroup], 'column', oneColumn['COLUMN_NAME']);
            if (!foundData) this.mainJson['condition'][oneGroup].push(template);
          })
        }
      );
    }
  }
}
