import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {Component, OnInit, Input, ViewChild, TemplateRef} from '@angular/core';
import {NzModalService} from 'ng-zorro-antd';
import {AppConfigService} from './../../service/app-config.service';
import {RequestDataService} from './../../service/request-data.service';
import {DataCenterService} from './../../service/data-center.service';
import {SettingColumnService} from './../../service/setting-column.service';
import {UtilsService} from './../../service/utils.service';
import {ListActionService} from '../../service/list-action.service';
import {ConfirmModalService} from '../../service/confirm-modal.service';

import {UUID} from 'angular2-uuid';
import * as _ from 'underscore';
import * as _sh from 'lodash';
import * as $ from 'jquery';
import * as localForage from 'localforage';
import {JumpCenterService} from "../../service/jump-center.service";
import {UserInfoService} from "../../service/user-info.service";
import PubSub from 'pubsub-js';
import {switchMap} from "rxjs/operators";

@Component({
    selector: 'app-list-form',
    templateUrl: './list-form.component.html',
    styleUrls: ['./list-form.component.css']
})
export class ListFormComponent implements OnInit {

    @ViewChild('childForm', {static: false}) childForm;

    @ViewChild('listColumn', {static: false}) listColumn;
    //父级组件的数据对象
    @Input() parentData: any = {
        conditionColumns: {
            columns: []
        },
        pageSize: null,
        nzPageSizeOptions: null
    };
    public userIsDeveloper: boolean = false; // 默认不是开发者

    // table 滚动条宽度
    public scrollWidth: string = '';
    public searchInit: Boolean = true;
    // 查询状态
    public searchState: boolean = false;//false表示未开始查询
    public operation: Boolean = true;
    public detail_links: any = {};
    public operationWidth: string = '53px';
    public serialNumberWidth: string = '106px';
    public menuId = null;
    // 分页下标
    public pageIndex: any = 1;
    // 每页显示数据
    public pageSize: any = this.parentData.pageSize ? this.parentData.pageSize : this.appConfigService.config.pageCount;
    // 总条数
    public total: any = 0;
    // 分组

    public nzPageSizeOptions: any = this.parentData.nzPageSizeOptions ? this.parentData.nzPageSizeOptions : [10, 20, 30, 50, 100, 200]

    // 当前table的表头
    public showColumns: any = [];

    // 分组表头
    public groupColumns: any = [];
    // 宽度配置
    public widthConfig = [];
    // 查询数据
    public conditionList: any[] = [];
    //排序字段的数组
    public sortArray: any[] = [];

    public checkbox = true;
    public allChecked = false;
    public indeterminate = false;

    private visibleCheck = {
        type: 'common'
    };

    // 列表展示列字段
    public settingListColumn: any = [];
    public settingConditionColumn: any = [];

    // 列表页帮助文档
    public listHelpUrl: string = '';

    public allListColChecked = true;
    public listColIndeterminate = false;

    // 配置列表显示项弹框排序方式
    public columnSortType: any = false;
    smallPlan = false;

    constructor(private requestDataService: RequestDataService,
                private appConfigService: AppConfigService,
                private dataCenterService: DataCenterService,
                private utils: UtilsService,
                private userInfoService: UserInfoService,
                private listAction: ListActionService,
                private modalService: NzModalService,
                private settingSerive: SettingColumnService,
                private jumpCenterService: JumpCenterService,
                private confirmModal: ConfirmModalService) {
    }

    refreshStatus(): void {
        const validData = this.parentData.data.data.filter(value => !value.disabled);
        const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
        const allUnChecked = validData.every(value => !value.checked);
        this.allChecked = allChecked;
        this.indeterminate = (!allChecked) && (!allUnChecked);
    }

    checkAll(value: boolean): void {
        this.parentData.data.data.forEach(data => {
            if (!data.disabled) {
                data.checked = value;
            }
        });
        this.refreshStatus();
    }

    handleClose(removedCondition: {}): void {
        // form 中数据清空
        this.conditionList = this.conditionList.filter(tag => tag !== removedCondition);
        _.each(this.childForm._validateForm['columns'], (item) => {
            if (removedCondition['column'] === item['column']) {
                item['model'] = null;
                if (_.contains(['date', 'datetime', 'dateRange', 'dateTimeRange'], item['component'])) {
                    item['modelDate'] = null;
                }
                if (_.contains(['poplist', 'multipopCondition', 'multipop'], item['component'])) {
                    item['modelName'] = '';
                }
            }
        });
        // 重新查询
        this.searchFilter();
    }


    sortClose(oneSort): void {
        _sh.each(this.showColumns, oneColumn => {
            let columnName = oneColumn.column.replace('_DICT', '');
            if (columnName == oneSort['column']) {
                delete oneColumn['sortType'];
            }
        })
        this.sortArray = this.sortArray.filter(tag => tag !== oneSort);
        // 重新查询
        this.parentData['sortArray'] = this.sortArray;
        this.searchFilter();
    }

    // 合并查询条件
    transformCondition(columns) {
        let condition = [];
        _.each(columns, (item) => {
            if (this.utils.Base_HasValue(item['model'])) {
                /**
                 * [obj 查询条件对象]
                 * column 字段值对应数据库字段
                 * label 标题
                 * code 查询字段值
                 * name 查询字段显示值用于字典
                 * @type {Object}
                 */
                let obj = {
                    column: item['column'],
                    title: item['lable'],
                    code: item['model'],
                    name: item['model'],
                    component: item['component']
                };
                if (_.contains(['select', 'poplist', 'inputDataFilter', 'poplistedit'], item['component'])) {
                    // poplist 存在 itemArray 不存在的情况（因为是需要点击在去查询JSON）
                    // 所以在查询条件会写的时候需要讲内容赋值
                    if (item['itemArray'] && item['itemArray'].length) {
                        const selected = _.find(item['itemArray'], (v) => {
                            return v['code'] === item['model'];
                        });
                        obj['name'] = selected['name'];
                    } else {
                        obj['name'] = item['modelName'];
                    }
                } else if (_.contains(['multiSelect', 'multipopCondition', 'multipop', 'multipopCondition'], item['component'])) {
                    obj['name'] = item['modelName'];
                    if (!this.utils.Base_HasValue(obj['name'])) {
                        let filterIndex = 1;
                        _sh.each(item['model'], oneModel => {
                            let onefilter = _sh.filter(item['itemArray'], {'code': oneModel});
                            if (filterIndex === 1) {
                                obj['name'] = _sh.get(onefilter, [0, 'name']);
                            } else {
                                if (this.utils.Base_HasValue(obj['name']))
                                    obj['name'] = obj['name'] + ' ' + _sh.get(onefilter, [0, 'name']);
                                else
                                    obj['name'] = _sh.get(onefilter, [0, 'name']);
                            }
                            filterIndex++;
                        })
                    }
                    obj['code'] = this.utils.Base_ArrayDelEmptyAndUniq(obj['code']);
                } else if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
                    obj['name'] = item['model'].join('~');
                    obj['bindColumns'] = item['bindColumns'];
                }
                condition.push(obj);
            }
        });
        return condition;
    }


    // 将searchCondition放入localForage
    async setSearchCondition(parentData, conditionList, searchSortArray) {
        let that = this;
        let key = 'condition_' + parentData.nowMenu.MENU_ID;
        await localForage.getItem(key).then(async (result: any) => {
            if (!that.utils.Base_HasValue(result)) {
                result = {
                    'conditionArray': [],
                    'sortArray': [],
                }
            }
            result['conditionArray'] = conditionList;
            result['sortArray'] = searchSortArray;
            await localForage.setItem(key, result);
        });
    }

    public checkConditionRequire() {
        let checked = true;
        if (this.utils.Base_HasValue(_sh.get(this.childForm, ['_validateForm', 'columns'], {}))) {
            _.each(this.childForm._validateForm['columns'], (oneColumn) => {
                if (_sh.get(oneColumn, ['require'])) {
                    if (!this.utils.Base_HasValue(oneColumn['model'])) {
                        checked = false;
                        this.confirmModal.show('error', {
                            title: oneColumn['lable'] + '是必选项。'
                        });
                    }
                }
            });
        }
        if (!checked) {
            this.clearData();
        }
        return checked;
    }

    public clearData() {
        this.parentData.data.data = null;
        this.total = 0;
        this.conditionList = [];
        this.sortArray = [];
        this.dataCenterService.refreshDataCenterInStorage();
    }

    /**
     * 过滤调用方法
     */
    public searchFilter(reset: boolean = true) {
        if (this.checkConditionRequire()) { // 如果是必填项
            this.conditionList = this.transformCondition(this.childForm._validateForm['columns']); // 来自于子窗体的字段。
            this.setSearchCondition(this.parentData, this.conditionList, this.sortArray); // 将查询条件放入缓存。

            // 覆盖当前数据到data-center并重新查询
            _.each(this.dataCenterService._dataObject.list, (item) => {
                if (item['id'] === this.parentData.id) {
                    item['searchCondition'] = this.conditionList;
                }
            });
            let condition = {};
            _.each(this.conditionList, (item) => {
                // 如果是daterange 类型需要将字段从bindColumns中取出
                if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
                    _.each(item.code, (value, index) => {
                        const column = item['bindColumns'][index];
                        condition[column] = value;
                    });
                } else if (_.contains(['multiSelect', 'multipopCondition', 'multipop', 'multipopCondition'], item['component'])) {
                    condition[item.column] = item.code;
                } else {
                    condition[item.column] = _sh.trim(item.code);
                }
            });
            this.searchData(reset, condition, this.sortArray);
            // 如果重新查询。则清楚掉详情的条件
            this.parentData.detailCondition = {};
            this.dataCenterService.refreshDataCenterInStorage();
        }
    }

    /**
     * 清除条件调用方法
     */
    resetFilter() {
        this.childForm.resetForm();
        this.conditionList = []; // 清楚查询条件
        this.sortArray = []; // 清楚排序条件
        this.parentData['sortArray'] = [];
        _sh.each(this.showColumns, oneColumn => {
            delete oneColumn['sortType']
        })
        if (this.searchInit){
            this.searchFilter();
        }

    }

    async initSearchCondition(parentData) {
        // 如果缓存中有data-center数据。则获取之后加载
        let that = this;
        let key = 'condition_' + parentData.nowMenu.MENU_ID;
        await localForage.getItem(key).then((result: any) => {
            let conditionForage = result;
            if (!that.utils.Base_HasValue(result)) {
                conditionForage = {
                    'conditionArray': [],
                    'sortArray': [],
                }
            }
            parentData.searchCondition = conditionForage['conditionArray'];
            const searchCondition = parentData.searchCondition;
            // 如果有存储查询条件则需要将值合并到conditionColumns中
            if (this.utils.Base_HasValue(searchCondition)) {
                _.each(parentData.conditionColumns.columns, (item) => {
                    const condition = _.find(searchCondition, (searchItem) => {
                        return searchItem['column'] === item['column'];
                    });
                    if (this.utils.Base_HasValue(condition)) {
                        item['model'] = condition['code'];
                        if (_.contains(['poplist'], item['component'])) {
                            item['modelName'] = condition['name'];
                        }
                    }
                });
            }

            //恢复排序。
            parentData['sortArray'] = conditionForage['sortArray'];
            this.sortArray = parentData['sortArray'];

            this.conditionList = this.transformCondition(parentData.conditionColumns.columns);
        });
    }

    // 点击配置弹框表头排序方式
    sortListColumn() {
        this.listColumn.sortName(this.columnSortType ? 'letter' : 'index');
    }


    // 弹框标题全选反选
    updateAllListColChecked() {
        this.listColIndeterminate = false;
        if (this.allListColChecked) {
            this.parentData.data.showColumns.columns.forEach(item => item.checked = true);
        } else {
            this.parentData.data.showColumns.columns.forEach(item => item.checked = false);
        }
        this.listColumn.checkedColumns = [];
        _.each(this.parentData.data.showColumns.columns, item => {
            if (item['checked']) {
                this.listColumn.checkedColumns.push(item['column']);
            }
        });
    }

    // 修改标题全选框选中状态
    updateCheckAndIndeter(model): void {
        this.allListColChecked = model.checked;
        this.listColIndeterminate = model.indeterminate;
    }


    // 弹框设置列表显示列
    settingColumns(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>) {
        this.columnSortType = false;
        let wrapper = $('#setting-list' + this.parentData.id);
        let rightLen = document.documentElement.clientWidth - 20 - wrapper.offset().left;
        let styleObj = {position: 'absolute', top: wrapper.offset().top + 25 + 'px', right: rightLen + 'px'};
        this.modalService.create({
            nzTitle: tplTitle,
            nzContent: tplContent,
            nzStyle: styleObj,
            nzOnOk: (response) => {
                this.confirmSettingColumns(this.listColumn);
            }
        });
    }

    // 配置列表显示列
    confirmSettingColumns(result) {
        if (this.utils.Base_HasValue(result)) {
            if (!result.checkedColumns.length) {
                this.confirmModal.show('warning', {
                    title: '温馨提示',
                    content: '至少选择一个展示列！'
                });
                return;
            }
            this.settingListColumn = result.checkedColumns;
            this.transformSettingList();
            let settings = this.settingSerive['settings'][this.parentData.nowMenu.MENU_ID];
            if (!this.utils.Base_HasValue(settings)) {
                this.settingSerive['settings'][this.parentData.nowMenu.MENU_ID] = {};
            }
            this.settingSerive['settings'][this.parentData.nowMenu.MENU_ID]['listColumns'] = this.settingListColumn;
            this.settingSerive.refreshSettingsInStorage();
        }
    }

    // 设置列表展示列
    transformSettingList() {
        if (this.settingListColumn.length) {
            _.each(this.parentData.data.showColumns.columns, column => {
                if (_.contains(this.settingListColumn, column['column'])) {
                    column['setShow'] = true;
                } else {
                    column['setShow'] = false;
                }
                column['show'] = column['iscondition'] || (column['visible'] && column['setShow']);
            });
        } else {
            _.each(this.parentData.data.showColumns.columns, oneColumn => {
                oneColumn['setShow'] = true;
                oneColumn['show'] = oneColumn['iscondition'] || (oneColumn['visible'] && oneColumn['setShow']);
            });
        }
        this.showColumns = this.parentData.data.showColumns.columns.filter(item => item.show);
        this.groupColumns = _sh.get(this.parentData, ['data', 'showColumns', 'groupColumns'], []);
        this.smallPlan = false; // 控制列表页小计显示不显示
        //每个页面对于关键数量做小计，showColumn里配置num:true;
        _.each(this.showColumns, oneColumn => {
            oneColumn['sum'] = 0;
            if (oneColumn['num']) {
                this.smallPlan = true;
                _.each(this.parentData.data.data, oneData => {
                    oneColumn['sum'] += oneData[oneColumn['column']];
                });
            }
        });
    }

    // 查询后的数据进行封装
    transformResult(data) {
        this.transformSettingList();
        this.scrollWidth = data.showColumns.scrollWidth || null;

        this.operation = _sh.get(data, ['showColumns', 'operation'], true);
        this.detail_links = _sh.get(data, ['showColumns', 'detail_links'], {});

        if (this.operation == false) {
            this.operationWidth = "0px";
            this.serialNumberWidth = "53px";
        }

        this.total = data.page.rowCount;
        this.parentData.titleCount = this.total;
        this.parentData.pageIndex = data.page.pageNum;
        // 计算每个栏目宽度 总宽度-左边菜单宽度-间距值
        let tableWidth = document.documentElement.clientWidth - 240 - 44;
        if (this.scrollWidth) {
            tableWidth = +this.scrollWidth.split('px')[0];
        }
        // 表格宽度-CheckBox-序号-详情列宽度
        let columnWidth = (tableWidth - 10 - 50 - 50) / this.showColumns.length;
        _.each(this.showColumns, oneColumn => {
            // 如果没有设置过宽度的，那么用计算宽度
            if (!this.utils.Base_HasValue(oneColumn['width'])) {
                oneColumn['width'] = columnWidth.toFixed(0) + 'px';
                oneColumn['customizeWidth'] = false; // 非自定义宽度
            } else {
                oneColumn['customizeWidth'] = true;  // 自定义宽度
            }

            if (this.utils.Base_HasValue(this.parentData['sortArray'])) {
                this.sortArray = this.parentData['sortArray'];
                _sh.each(this.sortArray, oneSort => {
                    _sh.each(this.showColumns, oneColumn => {
                        let columnName = oneColumn.column.replace('_DICT', '');
                        if (columnName == oneSort['column']) {
                            oneColumn['sortType'] = oneSort['type']
                        }
                    })
                })
            }
        });
        this.widthConfig = [];
        if (this.checkbox) this.widthConfig.push('10px'); // 多选框
        if (this.operation) this.widthConfig.push('50px'); // 操作
        this.widthConfig.push('50px'); // 序号
        _.each(this.showColumns, oneColumn => {
            // 如果没有设置过宽度的，那么用计算宽度
            if (oneColumn['visible']) this.widthConfig.push(oneColumn['width']);
        });


        // 如果重详情或者修改页面过来的数据选中当前条
        if (this.utils.Base_HasValue(this.parentData.detailCondition)) {
            let tmp = {};
            let checkObj = {};
            _.each(this.parentData.data.showColumns.columns, (OneColumn) => {
                //只将 iscondition 为 true 的作为条件传入详情页面。
                let bFind = false;
                if (_.has(OneColumn, 'iscondition')) {
                    bFind = OneColumn['iscondition'];
                }
                if (bFind) {
                    _.each(this.parentData.detailCondition, (con, key) => {
                        if (key === OneColumn['conditionColumn']) {
                            checkObj[OneColumn['column']] = con;
                        }
                    });
                }
            });
            _.each(this.parentData.data.data, (item) => {
                item['active'] = true;
                _.each(checkObj, (con, key) => {
                    if (item[key] !== con) {
                        item['active'] = false;
                    }
                });
            });
        }
    }

    // 通用封装数据结果
    transformData(data) {
        // 重新覆盖 datacenter 数据
        this.parentData.pageSize = this.pageSize;
        this.parentData.data = data;
        this.transformResult(data);
        // 覆盖当前数据到data-center;
        _.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === this.parentData.id) {
                item = _.extend(item, this.parentData);
            }
        });
        this.dataCenterService.refreshDataCenterInStorage('transformData,463');
    }

    searchData(reset: boolean = false, condition?: {}, pSortBy?): void {
        this.searchState = true;
        if (reset) {
            this.parentData.pageIndex = 1;
        }
        if (this.parentData.passCondition) {
            condition = Object.assign(condition, this.parentData.passCondition);
        }
        this.requestDataService.getPaginationData(this.parentData.searchId, condition || {}, this.parentData.pageIndex, this.pageSize, pSortBy).subscribe((data: any) => {
            this.transformData(data);
            this.execColor(); // 处理颜色
            this.searchState = false;
        });
    }

    doJumpForColumn(oneData, pColumn): void {
        let that = this;
        let jumpConfig = _sh.get(pColumn, ['jumpConfig'], null);
        if (this.utils.Base_HasValue(jumpConfig)) {
            oneData['checked'] = true;
            let simulationParentData = {
                data: {
                    data: [oneData]
                }
            }
            that.jumpCenterService.execJumpPage(jumpConfig, simulationParentData, null, null);
        } else {
            this.confirmModal.show('error', {
                'title': '执行错误',
                'content': "未配置跳转方式。"
            });
        }
    }

    gotoDetail(oneData): void {
        let that = this;

        function setDetailConditons(pData) {
            function isCondtion(OneColumn) {
                let bFind = false;
                if (_.has(OneColumn, 'iscondition')) {
                    bFind = OneColumn.iscondition;
                }
                return bFind;
            }

            let tmp = {};
            _.each(that.showColumns, function (OneColumn) {
                //只将 iscondition 为 true 的作为条件传入详情页面。
                if (isCondtion(OneColumn)) {
                    tmp[OneColumn['conditionColumn']] = pData[OneColumn['column']];
                }
            });
            return tmp;
        }

        let jumpConfig = _sh.get(that.parentData, ['data', 'showColumns', 'jumpConfig'], null);

        if (this.utils.Base_HasValue(jumpConfig)) {
            oneData['checked'] = true;
            let simulationParentData = {
                data: {
                    data: [oneData]
                }
            }
            that.jumpCenterService.execJumpPage(jumpConfig, simulationParentData, null, null);
        } else {
            let isGotoDetail = false;
            _.each(that.dataCenterService._dataObject.list, (item) => {
                if (item['id'] === this.parentData.id) {
                    let updateUrl = item['nowMenu'].MENU_UPDATE_URL;
                    if (!_.isEmpty(updateUrl)) {
                        item = _.extend(item, that.parentData);
                        item['data'] = {};
                        item['detailCondition'] = setDetailConditons(oneData);
                        isGotoDetail = true;
                        if (updateUrl === 'index.detail') {
                            item['formType'] = 'readOnlyForm';
                        } else {
                            item['formType'] = 'customForm';
                            item['btnName'] = updateUrl;
                        }
                    }
                }
            });
            if (this.utils.Base_HasValue(that.parentData.custormFormType)) {
                that.parentData.custormFormType = '';
            }
            if (isGotoDetail) {
                that.dataCenterService.refreshDataCenterInStorage();
            }
        }

    }

    selectOneData(pListData): number {
        let iCount = 0;
        _.each(pListData, oneData => {
            if (oneData['checked']) {
                iCount++;
            }
        });
        if (iCount == 0) {
            this.confirmModal.show('warning', {
                'title': '温馨提示',
                'content': '请至少选择一条纪录！'
            });
            console.error('this action must select only one record ');
            return iCount;
        } else if (iCount > 1) {
            this.confirmModal.show('warning', {
                'title': '温馨提示',
                'content': '请只选择一条纪录！'
            });
            console.error('this action must select only one record ');
            return iCount;
        }
        return iCount;
    }

    setDetailConditons(pData) {
        function isCondtion(OneColumn) {
            let bFind = false;
            if (_.has(OneColumn, 'iscondition')) {
                bFind = OneColumn.iscondition;
            }
            return bFind;
        }

        let conData;
        _.each(pData, function (oneData) {
            if (oneData['checked']) {
                conData = oneData;
            }
        });
        let selectedData = _.filter(pData, item => item['checked']);
        let showParams = [];
        let condition = {};
        _.each(this.parentData.data.showColumns['columns'], function (OneColumn) {
            //只将 iscondition 为 true 的作为条件传入详情页面。
            if (isCondtion(OneColumn)) {
                condition[OneColumn['conditionColumn']] = selectedData[0][OneColumn['column']];
            }
            if (OneColumn['showParam']) {
                showParams.push({
                    'column': OneColumn['column'],
                    'model': selectedData[0][OneColumn['column']],
                    'title': OneColumn['title']
                });
            }
        });
        return {
            condition: condition,
            showParams: showParams
        };
    }

    // 列表跳转列表后返回
    gotoPrevList() {
        let menuId = this.parentData.beforeMenuId;
        let menu = this.utils.Base_getMenulist(menuId);
        let appObj = this.utils.Base_getApplist(menuId);
        let that = this;
        let uuid = UUID.UUID();
        let beforeParentId = this.parentData.id;
        this.parentData = {
            id: uuid, // 唯一标识
            nowMenu: menu, // 菜单ID 用于详情返回
            title: menu.MENU_NAME, // 菜单名称 用户tab展示
            formType: 'listForm', // 菜单类型 用于tab过滤显示模板
            searchId: appObj ? appObj.SEARCH_ID : '', // 查询json 用户列表分页查询
            data: {}, // 查询返回数据,
            conditionColumns: {},
            listHelpUrl: appObj ? appObj.HELP_URL : '',
            addHelpUrl: appObj ? appObj.ADD_HELP_URL : '',
            pageIndex: 1,
            pageSize: this.appConfigService.config.pageCount,
            searchCondition: this.parentData.passSearchCondition || []
        };
        // 覆盖当前数据到data-center;
        _.each(this.dataCenterService._dataObject.list, (item) => {
            if (item['id'] === beforeParentId) {
                delete item['beforeMenuId'];
                delete item['showParams'];
                delete item['passCondition'];
                item = _.extend(item, this.parentData);
            }
        });
        this.dataCenterService._dataObject.nowMenu = menu;
        // 后面查询后会重新放入缓存。这个时候暂时不需要
        this.dataCenterService.refreshDataCenterInStorage();
        this.init();
    }

    // 跳转列表
    gotoInnerList(btn, parentData) {
        let menuId = btn.ACTION_OPERATE;
        if (this.utils.Base_HasValue(menuId)) {
            if (this.selectOneData(parentData.data.data) != 1) {
                return;
            }

            let menu = this.utils.Base_getMenulist(menuId);
            let appObj = this.utils.Base_getApplist(menuId);
            let that = this;
            let uuid = UUID.UUID();
            let beforeParentId = this.parentData.id;
            this.parentData = {
                id: uuid, // 唯一标识
                beforeMenuId: parentData.nowMenu.MENU_ID,
                nowMenu: menu, // 菜单ID 用于详情返回
                title: menu.MENU_NAME, // 菜单名称 用户tab展示
                formType: 'listForm', // 菜单类型 用于tab过滤显示模板
                searchId: appObj ? appObj.SEARCH_ID : '', // 查询json 用户列表分页查询
                data: {}, // 查询返回数据,
                conditionColumns: {},
                listHelpUrl: appObj ? appObj.HELP_URL : '',
                addHelpUrl: appObj ? appObj.ADD_HELP_URL : '',
                pageIndex: 1,
                pageSize: this.appConfigService.config.pageCount,
                passCondition: this.setDetailConditons(parentData.data.data)['condition'],
                showParams: this.setDetailConditons(parentData.data.data)['showParams']
            };
            if (this.conditionList.length) {
                this.parentData.passSearchCondition = Object.assign([], this.conditionList);
                this.conditionList = [];
            }
            // 覆盖当前数据到data-center;
            _.each(this.dataCenterService._dataObject.list, (item) => {
                if (item['id'] === beforeParentId) {
                    item = _.extend(item, this.parentData);
                }
            });
            this.dataCenterService._dataObject.nowMenu = menu;
            // 后面查询后会重新放入缓存。这个时候暂时不需要
            // this.dataCenterService.refreshDataCenterInStorage();
            this.init();
        }

    }

    doAction(pBtn): void {
        // 判断按钮的动作值，list代表增删改查按钮
        if (pBtn.ACTION_VALUE === 'list') {
            this.gotoInnerList(pBtn, this.parentData);
        }
        if (this.utils.Base_HasValue(this.parentData.custormFormType)) {
            this.parentData.custormFormType = '';
        }
        this.listAction.doAction(pBtn, this.parentData, "listForm", null)
            .pipe(
                switchMap(lv01 => lv01 as Observable<any>),
                switchMap(lv02 => lv02 as Observable<any>),
                switchMap(lv03 => lv03 as Observable<any>)
            )
            .subscribe({
                next: lv04 => {
                    const status = _sh.get(lv04, ['status'], 200);
                    if (status == 500) {
                        let errorMsg = _sh.get(lv04, [0, 'error'], null);
                        errorMsg = _sh.get(lv04, ['error', 'message'], errorMsg);
                        this.confirmModal.show('error', {
                            'title': '执行错误',
                            'content': errorMsg
                        });
                    } else if (status == 200) {
                        let result = _sh.get(lv04, ['result'], 'success');
                        if (result == 'success') {
                            this.confirmModal.show('success', {'title': '执行成功'});
                        } else if (result == 'error') {
                            let errorMsg = _sh.get(lv04, ['message'], null);
                            this.confirmModal.show('error', {
                                'title': '执行错误',
                                'content': errorMsg
                            });
                        }
                        this.searchFilter();
                    }
                },
                error: err => console.error('something wrong occurred: ' + err),
                complete: () => console.log('done')
            });
    }



    /**
     * 排序方法
     *
     * @param pType 排序类型
     * @param pColumn 排序字段
     */
    sortColumn(pType, pColumn): void {
        let hasColumn = _sh.filter(this.sortArray, oneColumn => {
            let found = true;
            let columnName = pColumn.column.replace('_DICT', '');
            if (columnName != oneColumn['column']) {
                found = false;
            }
            return found;
        });
        let sortObject = {
            'column': pColumn.column.replace('_DICT', ''),
            'type': pType,
            'title': pColumn['title'],
            'typeName': pType == 'DESC' ? '降序' : '升序'
        };
        hasColumn = _sh.get(hasColumn, [0], {});
        if (this.utils.Base_HasValue(hasColumn)) {
            if (hasColumn['type'] == pType) {
                // 如果存在的，并且类型一样的，那么删除这行记录。
                this.sortArray = _sh.filter(this.sortArray, oneColumn => {
                    let found = true;
                    if (oneColumn['column'] == hasColumn['column']) {
                        found = false;
                    }
                    return found;
                });
            } else {
                // 如果存在的，并且类型不一样的，那么先删除，后添加。
                this.sortArray = _sh.filter(this.sortArray, oneColumn => {
                    let found = true;
                    if (oneColumn['column'] == hasColumn['column']) {
                        found = false;
                    }
                    return found;
                });
                this.sortArray.push(sortObject);
                pColumn['sortType'] = pType;
            }
        } else {
            // 如果不存在，那么添加
            this.sortArray.push(sortObject);
            pColumn['sortType'] = pType;
        }
        this.parentData['sortArray'] = this.sortArray;
        this.searchFilter();
    }


    async transformInitData(values, result, menuId) {
        if (this.utils.Base_HasValue(result)) {
            // 处理该列表column的数据
            result = JSON.parse(result);
            this.settingListColumn = result[menuId] ? (result[menuId]['listColumns'] || []) : [];
            this.settingConditionColumn = result[menuId] ? (result[menuId]['conditionColumns'] || []) : [];
        }
        let conditionColumns = values[0]['conditionColumns'][0] || {};
        if (this.settingConditionColumn.length) {
            _.each(conditionColumns.columns, condition => {
                if (_.contains(this.settingConditionColumn, condition['column'])) {
                    condition['type'] = 'common';
                } else {
                    condition['type'] = 'normal';
                }
            });
        }
        // 冗余json配置额title字段。设置当前显示title为菜单名称。为了tab显示标题和查询标题一致
        conditionColumns.oldTitle = conditionColumns.title;
        conditionColumns.title = this.parentData.nowMenu.MENU_NAME;
        this.parentData.conditionColumns = conditionColumns;
        this.parentData.conditionColumns.needInit = true;
        await this.initSearchCondition(this.parentData);
        let condition = {};
        _.each(this.conditionList, (item) => {
            // 如果是daterange 类型需要将字段从bindColumns中取出
            if (_.contains(['dateRange', 'dateTimeRange'], item['component'])) {
                _.each(item.code, (value, index) => {
                    const column = item['bindColumns'][index];
                    condition[column] = value;
                });
            } else {
                condition[item.column] = item.code;
            }
        });
        if (this.searchInit)
            this.searchData(false, condition, this.parentData['sortArray']);
        else {
            // this.childForm.resetForm();  // 默认不执行查询会出现条件输入无效及已填写必填项还是报错的问题
                                            // 同时注释后会出现打开报表会自动填入上次查询条件
            this.clearData();
        }
    }

    buildData(pData, pType, pRoleIdList) {
        let data = _sh.uniqBy(pData, 'ACTION_ID');
        let arr = [];
        _sh.each(data, oneData => {
            let obj = {}
            let oneColumnData = _sh.get(oneData, 'ACTION_ID_DICT_ARRAY');
            let columns = _sh.keys(oneColumnData);
            _sh.each(columns, oneColumn => {
                obj[oneColumn] = oneColumnData[oneColumn];
            });
            let actionTypeList = _sh.split(obj['ACTION_TYPE'], ',');
            if (_.contains(actionTypeList, pType)) {
                obj['MENU_ID'] = oneData['MENU_ID'];
                obj['MENU_NAME'] = oneData['MENU_ID_DICT'];
                obj['ROLE_ID'] = _sh.join(pRoleIdList, ',');
                arr.push(obj);
            }
        });
        return arr;
    }

    // 加载数据
    init() {
        if (this.parentData.pageSize) {
            this.pageSize = this.parentData.pageSize;
        }
        // 如果该tab数据不存在则需要查询
        if (!this.utils.Base_HasValue(this.parentData.data)) {
            // 先获取查询条件和按钮信息
            // 从缓存中取出查询值
            // 带入查询条件查询列表
            const role$ = observableForkJoin(
                this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_user_role.json', {}),
            );
            role$.subscribe(
                roleValues => {
                    let roleIdList = [];
                    _sh.each(roleValues[0]['data'] || [], oneRole => {
                        roleIdList.push(oneRole['ROLE_ID']);
                    })
                    this.menuId = _sh.cloneDeep(this.parentData.nowMenu.MENU_ID);
                    const parallel$ = observableForkJoin(
                        this.requestDataService.getConditionData(this.parentData.searchId),
                        this.requestDataService.getDataByCondition(this.appConfigService.config.sql_path + '/menu/system_role_menu_action.json', {
                            'ROLE_ID': roleIdList,
                            'MENU_ID': this.menuId
                        })
                    );
                    parallel$.subscribe(
                        values => {

                            console.log("list-form.component.ts 01 ", values[0]);

                            let nzPageSizeOptions = _sh.get(values,[0,'nzPageSizeOptions'] , null);
                            if (this.utils.Base_HasValue(nzPageSizeOptions)) {
                                this.nzPageSizeOptions = nzPageSizeOptions;
                            }

                            this.parentData.actionArray = [];
                            let jsonButtons = _sh.get(values, ['0', 'buttons'], null);
                            let actionArray = this.buildData(_sh.get(values, ['1', 'data'], []), 'list', roleIdList);
                            if (this.utils.Base_HasValue(jsonButtons)) {
                                _sh.each(actionArray, oneAction => {
                                    _sh.each(jsonButtons, oneBtn => {
                                        if (oneBtn === oneAction['ACTION_VALUE']) {
                                            this.parentData.actionArray.push(oneAction);
                                        }
                                    });
                                });
                            } else {
                                this.parentData.actionArray = actionArray;
                            }
                            this.parentData.title = _sh.get(values, ['0', '_comment'], null) ?
                                _sh.get(values, ['0', '_comment'], null) : this.parentData.title;
                            this.parentData.showTitleCount = _sh.get(values, ['0', 'showTitleCount'], false);
                            this.searchInit = _sh.get(values, ['0', 'showColumns', 'searchInit'], true); // 是否默认执行查询。
                                                                                                          // 页面刷新时该值会丢失

                            let menuId = this.parentData.nowMenu.MENU_ID;
                            this.settingSerive.getSettingsInfoStorage().then((result: string) => {
                                this.transformInitData(values, result, menuId); // 第一次查询
                            });
                        }
                    );
                }
            );


        } else {
            this.menuId = this.parentData.nowMenu.MENU_ID;
            this.settingSerive.getSettingsInfoStorage().then((result: string) => {
                if (this.utils.Base_HasValue(result)) {
                    // 处理该列表column的数据
                    result = JSON.parse(result);
                    this.settingListColumn = result[this.menuId] ? (result[this.menuId]['listColumns'] || []) : [];
                }
                this.transformResult(this.parentData.data);
                this.initSearchCondition(this.parentData);
                this.execColor();
            });
        }
        this.listHelpUrl = this.parentData.listHelpUrl;
    }


    // 监听 needSearch 主要用于列表页的按钮点击之后刷新列表
    ngDoCheck(): void {
        if (!!this.parentData.needSearch) {
            this.searchFilter();
            this.parentData.needSearch = false;
        }
    }

    gotoDesign(): void {
        let searchId = this.parentData.searchId;
        window.open('HulkConfigDesignList/?searchId=' + searchId, '_blank');
    }

    gotoReadOnly() {
        let searchId = this.parentData.searchId;
        window.open('HulkConfigDesignReadOnly/?searchId=' + searchId, '_blank');
    }

    gotoDataBuild() {
        let searchId = this.parentData.searchId;
        window.open('HulkConfigDataBuild/?searchId=' + searchId, '_blank');
    }


    gotoTableDesign() {
        let searchId = this.parentData.searchId;
        window.open('HulkConfigTableDesign', '_blank');
    }

    gotoHulkConfig(): void {
        window.open("HulkConfig");
    }


    showThisColumn(column: any) {
    }

    ngOnInit() {
        const user = this.userInfoService.getUserInfo();
        this.userIsDeveloper = _sh.get(user, 'POSITION', null) == "hdbs_developer";
        this.init();
        PubSub.subscribe('listRefresh', (msg, _menuId) => {
            let menuId = _sh.get(this.parentData, ['nowMenu', 'MENU_ID'], null);
            if (menuId === _menuId) {
                this.searchFilter();
            }
        });
    }

    // 处理颜色
    execColor() {

        let colorResult = {};

        function setColor(pOneColorConfig, pResultList) {
            let indexRow = 0;
            _.each(pOneColorConfig['columns'], oneColumn => {
                _sh.each(pResultList, oneRow => {
                    let oldColor = _sh.get(colorResult, [oneColumn, indexRow], null);
                    if (!oldColor) {
                        _sh.set(colorResult, [oneColumn, indexRow], '');
                    }
                    if (oneRow !== '') { // 新颜色不是 空色，那么覆盖老颜色
                        _sh.set(colorResult, [oneColumn, indexRow], oneRow); // 老颜色
                    }
                    indexRow++;
                })
            })
        }

        let colorConfig = _sh.get(this.parentData, ['data', 'showColumns', 'colorConfig'], null);
        if (this.utils.Base_HasValue(colorConfig)) {
            _sh.each(colorConfig, oneColorConfig => {
                let resultList = [];
                let dataList = _sh.get(this.parentData, ['data', 'data'], []);
                _sh.each(dataList, oneRow => {
                    let tempRow = _sh.cloneDeep(oneRow);
                    tempRow['checked'] = true;
                    let oneCheckData = {
                        'data': {
                            'data': [tempRow]
                        }
                    }
                    let checkResult = this.dataCenterService.doCheck(oneColorConfig, null, 'inRow', 'listForm', oneCheckData, null, false);
                    if (!checkResult) {
                        resultList.push(oneColorConfig['color']);
                    } else {
                        resultList.push('');
                    }
                })
                setColor(oneColorConfig, resultList);

            });
            _sh.set(this.parentData, ['data', 'showColumns', 'colorResult'], colorResult);
        }

    }

}

